.foster-image {
  object-fit: cover;
  width: 100%;
  height: 252px;
}

.custom-grid {
  margin: 0 auto;
  text-align: center;
  width: 50% !important;
}

.flickr-box {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.foster-button {
  display: flex;
  justify-content: center;
}

@media(max-width: 991px) {
  .foster-image  {
    width: 668px;
    height: 252px;
  }
}

@media(max-width: 420px) {
  .foster-image {
    height: 250px;
  }
}