.banner-title {
  font-size: 30px;
  text-align: start;
  line-height: 45px;
  letter-spacing: 3px;
}
.banner-title span{
  font-size: 45px;
}
.banner-title small {
  font-size: 20px;
}
.service-list-section .title1.title5 .title-inner1 {
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.service-list-section .title1 h4 {
  line-height: 28px;
  color: #777777;
}
.service-list-section .title1 h4, .service-list-section .title1.title5 .title-inner1 {
  text-align: left;
}
.contain-banner {
  position: absolute;
  top: 0;
  left: 0;
  clear: float;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.contain-banner h2 {
  color: #f0f0f0 !important;
}


/* old service place */

.service-list-section .bg-paw {
  position: absolute;
  left: -14%;
  top: 50%;
}
.service-list-section .home-service-box .hr-box {
  padding-left: 0%;
  padding-right: 90%;
  margin-bottom: 1.5rem;
}
.service-list-section .home-service-box .service-title {
  font-weight: 700;
}
.service-list-section .service-box-phone {
  display: none;
}
.service-list-section .service-box-website .service, .service-list-section .service-box-phone .service {
  width: 130px;
  height: 130px;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-list-section .home-service-box {
 /* service bath */
 /* service bath end */
 /* service meal */
 /* service meal end */
 /* service-together */
 /* service-together end */
 /* service walk */
 /* service walk end */
}
.service-list-section .home-service-box .home-service-title {
  font-size: 3rem;
}
.service-list-section .home-service-box h3 {
  font-size: 1.3rem;
  letter-spacing: 1.5px;
  font-weight: unset;
}
.service-list-section .home-service-box h3 span {
  color: #ff7e8e;
}
.service-list-section .home-service-box .service-title {
  font-size: 1.7rem;
  color: rgb(49, 49, 49);
}
.service-list-section .home-service-box .service-bath span, .service-list-section .home-service-box .service-meal span, .service-list-section .home-service-box .service-together span, .service-list-section .home-service-box .service-walk span {
  display: none;
  font-size: 1rem;
}
.service-list-section .home-service-box .service-bath .col-6, .service-list-section .home-service-box .service-meal .col-6, .service-list-section .home-service-box .service-together .col-6, .service-list-section .home-service-box .service-walk .col-6, .service-list-section .home-service-box .service-bath .col-7, .service-list-section .home-service-box .service-meal .col-7, .service-list-section .home-service-box .service-together .col-7, .service-list-section .home-service-box .service-walk .col-7 {
  display: flex;
  align-items: center;
}
.service-list-section .home-service-box .service-bath-img {
  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  background-image: url("/assets/images/home/service/icon/bathing.svg");
}
.service-list-section .home-service-box .service-bath:hover .service-bath-img {
  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  background-image: url("/assets/images/home/service/icon/bathing-hover.svg");
}
.service-list-section .home-service-box .service-bath:hover span {
  display: unset;
  word-break: break-all;
}
.service-list-section .home-service-box .service-bath:hover .col-6, .service-list-section .home-service-box .service-bath:hover .col-7 {
  display: unset;
}
.service-list-section .home-service-box .service-meal-img {
  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  background-image: url("/assets/images/home/service/icon/together-old.svg");
}
.service-list-section .home-service-box .service-meal:hover .service-meal-img {
  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  background-image: url("/assets/images/home/service/icon/together-old-hover.svg");
}
.service-list-section .home-service-box .service-meal:hover span {
  display: unset;
  word-break: break-all;
}
.service-list-section .home-service-box .service-meal:hover .col-6, .service-list-section .home-service-box .service-meal:hover .col-7 {
  display: unset;
}
.service-list-section .home-service-box .service-together-img {
  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  background-image: url("/assets/images/home/service/icon/together.svg");
}
.service-list-section .home-service-box .service-together:hover .service-together-img {
  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  background-image: url("/assets/images/home/service/icon/together-hover.svg");
}
.service-list-section .home-service-box .service-together:hover span {
  display: unset;
  word-break: break-all;
}
.service-list-section .home-service-box .service-together:hover .col-6, .service-list-section .home-service-box .service-together:hover .col-7 {
  display: unset;
}
.service-list-section .home-service-box .service-walk-img {
  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  background-image: url("/assets/images/home/service/icon/walk.svg");
}
.service-list-section .home-service-box .service-walk:hover .service-walk-img {
  -webkit-transition: background-image 0.3s ease-in-out;
  transition: background-image 0.3s ease-in-out;
  background-image: url("/assets/images/home/service/icon/walk-hover.svg");
}
.service-list-section .home-service-box .service-walk:hover span {
  display: unset;
  word-break: break-all;
}
.service-list-section .home-service-box .service-walk:hover .col-6, .service-list-section .home-service-box .service-walk:hover .col-7 {
  display: unset;
}
.service-list-section .home-service-box .sitter-desc p {
  font-size: 1rem;
}
.service-list-section .home-service-box .sitter-desc p small {
  font-size: 1rem;
}
@media screen and (max-width: 992px) {
  .service-list-section .home-service-box .service-box-phone {
    display: unset;
 }
  .service-list-section .home-service-box .home-service-title {
    text-align: center;
 }
  .service-list-section .home-service-box.home-service-box .hr-box {
    padding-left: 45%;
    padding-right: 45%;
    margin-bottom: 1.5rem;
 }
  .service-list-section .home-service-box .service-box-website {
    display: none;
 }
  .service-list-section .home-service-box .service-box-button {
    display: flex;
    justify-content: center;
 }
  .service-list-section .home-service-box .service-box {
    height: 100%;
    display: flex;
    align-items: center;
 }
  .service-list-section .home-service-box .service-box service-title-text {
    font-size: 1.5rem;
 }
  .service-list-section .home-service-box .service-box span {
    font-size: 1rem;
 }
  .service-list-section .home-service-box .service-walk .col-6, .service-list-section .home-service-box .service-walk .col-7, .service-list-section .home-service-box .service-together .col-6, .service-list-section .home-service-box .service-together .col-7, .service-list-section .home-service-box .service-bath .col-6, .service-list-section .home-service-box .service-bath .col-7, .service-list-section .home-service-box .service-meal .col-6, .service-list-section .home-service-box .service-meal .col-7 {
    display: unset;
 }
  .service-list-section .home-service-box .service-bath span, .service-list-section .home-service-box .service-meal span, .service-list-section .home-service-box .service-together span, .service-list-section .home-service-box .service-walk span {
    display: unset;
    word-break: break-all;
 }
}

.service-box span {
  color: #777777;
}
.service-box .learn-more {
  color: #ff7e8e;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .service-list-section .home-service-box .service-carousel, .service-list-section .home-service-box .service-carousel .el-carousel__item {
    height: 200px;
 }
  .service-list-section .home-service-box .service-box service-title-text {
    font-size: 1.5rem;
 }
  .service-list-section .home-service-box .service-box span {
    font-size: 1rem;
 }
}
@media screen and (min-width: 320px) and (max-width: 426px) {
  .service-list-section .home-service-box .home-service-box h3 {
    text-align: center;
 }
}

/* old service place */

@media (max-width: 1440px) {
  .service-list-img-box img{
    width: 85%;
  }

}

@media (max-width: 1199px) {
  .service-list-section .home-service-box .service-title {
    font-size: 1.2rem;
  }
  .service-list-section .service-box-website .service {
    width: 85px;
    height: 85px;
  }
}

@media (max-width: 991px) {
  .service-list-img-box {
    display: flex;
    justify-content: center;
  }
  .service-list-img-box img{
    width: 60%;
  }
  .home-service-box .title1{
    margin-top: 30px;
  }
  .service-list-section .title1 h4, .service-list-section .title1.title5 .title-inner1 {
    text-align: center;
  }
  .download-box {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .service-list-section h2{
    font-size: 30px;
  }
  .service-list-img-box img{
    width: 90%;
  }
  .banner-title {
    font-size: 24px;
    text-align: start;
    line-height: 35px;
    letter-spacing: 3px;
  }
  .banner-title span {
    font-size: 35px;
  }
  .banner-title small {
    font-size: 20px;
  }
}

@media screen and (max-width: 376px) {
  .service-box-phone .service {
    width: 120px !important;
    height: 120px !important;
  }
  .service-title {
    font-size: 23px !important;
  }
}