.business-content h1{
  font-size: 42px;
}

.container-qa {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-qa .item-qa {
  border-bottom: 1px dashed #b7b7b7;
}

.qa-content a{
  color: rgb(251, 125, 140);
  border-bottom: rgb(251, 125, 140) solid 1px;
}

.qa-content p{
  font-size: 18px;
  line-height: 24px;
  margin-top: 25px;
}

@media (min-width: 1200px) {
  .container-qa {
      width: 1170px;
  }
}
@media (min-width: 992px) {
  .container-qa {
      width: 970px;
  }
}
@media (min-width: 768px) {
  .container-qa {
      width: 750px;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .qa-content .item-qa:first-child {
    margin-top: 1rem !important;
  }
  .business-content h1{
    font-size: 32px;
  }
}