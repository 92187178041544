.home1 {
  display:flex;
  align-items: center;
  padding-top: 30px;
}
.home-slider .home.home1, .center-slider .home.home1 {
  /* background-image: url("/assets/images/home-banner/1-1.png") !important; */
  background-image: unset !important;
}
.hero-banner, .home-slider .home.home1 {
  height: auto;
  margin-bottom: 50px;
}
.home-banner-cover {
  overflow: hidden;
  width: 100%;
  height: 95vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(139, 218, 223);
  display: fixed;
  z-index: -1;
}

.home-banner-1 {
  background-image: url("/assets/images/banner/home-banner/fluv-sitter-cat.png") !important;
}
.circle-box {
  width: 100vw;
  bottom: -15px;
  position: absolute;
}
/* banner 右邊 */
.banner-img-box img{
  width: 75%;
}
/* banner 左邊 */
.home-slider .slider-contain {
  height: unset !important;
}
.appstore-download-button, .googleplay-download-button{
  background-size: cover;
  border-radius: 0 !important;
  border-color: transparent !important;
}
.appstore-download-button {
  background-image: url("/assets/images/download_icon/app-store-badge.png");
  width: 223.95px;
  height: 69.44px;
}
.googleplay-download-button {
  background-image: url("/assets/images/download_icon/google-play-badge.png");
  width: 218.40px;
  height: 69.44px;
}
.hero-banner-title h1 {
  font-weight: 700;
}
.hero-banner-title h1, .hero-banner-title h3{
  font-size: 2.5rem;
  letter-spacing: 6px;
  line-height: 60px;
}
.hero-banner-text-cover .hero-banner-subtitle {
  font-weight: 400;
  font-size: 1.8rem;
}
.hero-banner-text-cover .hero-banner-subtitle, .hero-banner-text-cover .hero-banner-title {
  color: #2f353a !important;
  text-align: left;
}
.download-box img{
  width: 150px;
}
.hero-banner-text-box .card-header{
  border: 0px;
}
.hero-banner-box, .btn-solid{
  font-size: 18px !important;
  border-radius: 30px !important;
}
.herobanner-en-title {
  color: black;
  font-size: 2rem !important;
  line-height: 55px !important;
  font-weight: 700 !important;
}
.mobile-circle {
  overflow: hidden;
  display: none;
}
.mobile-circle-1 {
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 50%;
  left: 22%;
  bottom: -115px;
}
.mobile-circle-2 {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 50%;
  bottom: -74px;
  left: -34px;
}
.mobile-circle-3 {
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 50%;
  right: -100px;
  bottom: -150px;
}
@media (max-width: 1440px) {
  .banner-img-box img{
    width: 85%;
  }
  .home-slider .slider-contain {
    width: unset !important;
  }
  .hero-banner-title h1 {
    font-size: 2.4rem;
  }
}
@media (max-width: 1199px) {
  .banner-img-box img {
    width: 100%;
  }
  .hero-banner-title h1 {
    font-size: 2rem !important;
  }
  .herobanner-en-title {
    font-size: 1.8rem !important;
  }
  .appstore-download-button {
    width: 223.95px;
    height: 69.44px;
  }
  .googleplay-download-button {
    width: 218.40px;
    height: 69.44px;
  }
}
@media (max-width: 991px) {
  .home1 {
    padding-top: 0;
  }
  .banner-img-box img {
    width: 50%;
  }
  .col-12 .slider-contain {
    padding-top: 15px;
  }
}
@media (max-width: 992px) {
  .hero-banner-img {
    display: none !important;
  }
}
@media (max-width: 880px) {
  #circles .circleOne {
    r: 448;
  }
  #circles .circleTwo{
    r: 350;
  }
  #circles .circleThr{
    r: 353;
  }
  #circles .circleFour {
    r: 380;
  }
  #circles .circleFive {
    r: 338;
  }
}
@media (max-width: 767px) {
  #circles .circleOne {
    r: 548;
  }
  #circles .circleTwo{
    r: 450;
  }
  #circles .circleThr{
    r: 453;
  }
  #circles .circleFour {
    r: 480;
  }
  #circles .circleFive {
    r: 408;
  }
  .circle-box {
    bottom: -15%;
  }
  .banner-img-box img {
    width: 50%;
  }
}
@media (max-width: 525px) {
  .hero-banner, .home-slider .home.home1 {
    margin-bottom: 25px;
  }
  .appstore-download-button {
    width: 186.62px;
    height: 57.86px;
  }
  .googleplay-download-button {
    width: 182px;
    height: 57.86px;
  }
  .mobile-circle {
    display: unset;
  }
  .circle-box {
    display: none;
  }
}

@media (max-width: 480px) {
  #circles .circleOne {
    r: 648;
  }
  #circles .circleTwo{
    r: 450;
  }
  #circles .circleThr{
    r: 553;
  }
  #circles .circleFour {
    r: 480;
  }
  #circles .circleFive {
    r: 508;
  }
  .circle-box {
    bottom: -15%;
  }
  .hero-banner-title h1{
    font-size: 2.1rem;
    line-height: unset;
    letter-spacing: unset;
  }
  .home-banner-cover {
    height: 100vh;
  }
}

@media (max-width: 376px) {
  #circles .circleOne {
    r: 648;
  }
  #circles .circleTwo{
    r: 450;
  }
  #circles .circleThr{
    r: 553;
  }
  #circles .circleFour {
    r: 680;
  }
  #circles .circleFive {
    r: 508;
  }
  .circle-box {
    bottom: -35%;
  }
  .hero-banner, .home-slider .home.home1 {
    /* height: 65vh; */
  }
  .hero-banner-title h1 {
    font-size: 1.8rem !important;
  }
  .hero-banner-text-cover .hero-banner-subtitle {
    font-size: 1.6rem !important;
  }
  .download-box {
    justify-content: center;
  }
  .appstore-download-button {
    width: 158.51px;
    height: 49.6px;
  }
  .googleplay-download-button {
    width: 160.45px;
    height: 50.6px;
  }
}


