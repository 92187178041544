
.review-comment-box {
  background-color: white;
  margin: 10px;
  padding: 20px;
  border: solid #d3d3d3 1px;
  border-radius: 10px;
}
.review-comment-box p{
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  line-height: 18px;
}

.review-comment-box img {
  width:50px !important;
  height: 50px !important;
  border-radius: 50px;
  object-fit: cover !important;
  margin-bottom: 10px;
}