.shelter-image {
  display: flex;
  justify-content: start;
}
.button-box-about .btn{
  border: 2px solid rgb(231, 110, 126);
  background-image: linear-gradient(30deg, rgb(231, 110, 126) 50%, transparent 50%);
}
.shelter-image-bg {
  background-image: url("https://d85iobqkjw2r9.cloudfront.net/event/3048248_1.jpg");
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
}
.button-box-about {
  margin-top: 3rem;
  text-align: center;
}
.shelter-image,
.shelter-image-caver {
  border-radius: 0.25rem;
}
.about-button {
  color: white;
  background-color: rgb(231, 110, 126);
  font-size: 1.2rem;
  font-weight: 900;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 10px;
  text-decoration: none;
}
.about-button:hover {
  background-color: rgb(159, 112, 113);
  color: white;
  text-decoration: none;
}
.about-shelter-title {
  color: white;
  font-weight: 900;
  font-size: 2.5rem;
}
.about-shelter p {
  color: white;
  font-weight: 900;
  font-size: 1.5rem;
}
.about-shelter {
  border-radius: 0.25rem;
  background-color: #79c7ce;
  opacity: 0.8;
  position: absolute;
  width: 40rem;
  height: 20rem;
  top: 6rem;
  left: 33rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.csr-shelter {
  border-radius: 0.25rem;
  background-color: #79c7ce;
  opacity: 0.8;
  position: absolute;
  width: 40rem;
  height: 20rem;
  top: 6rem;
  left: -20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-me-title {
  /* font-family: Helvetica, Arial, sans-serif; */
  font-size: 3rem;
}
.about-me-subtitle {
  color: gray;
  font-size: 20px;
  line-height: 30px;
}
.about-me-content p {
  font-size: 20px;
  line-height: 30px;
}
.about-me-text {
  order: 0;
}
.about-me-img {
  width: 100%;
}
.fluv-team-image {
  border-radius: 50%;
  width: 250px;
  height: 250px;
}
.button-box .button-style {
  font-size: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#carouselExampleControls .carousel-item{
  width: 70%;
}

@media screen and (min-width: 320px) and (max-width: 960px) {
  .about-me-title {
    font-size: 1.8rem;
  }
  .about-me-text {
    padding: 1rem !important;
  }
  .about-me-row {
    margin-bottom: 1rem !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 960px) {
  .about-me-title {
    font-size: 2rem;
  }
  .about-me-subtitle {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 760px) {
  .button-box .button-style {
    font-size: 1.2rem;
  }
  .about-me-title {
    margin-top: 0 !important;
  }
  .about-me-subtitle {
    font-size: 1rem;
  }
  .button-box {
    margin-top: 1rem !important;
    display: flex;
    justify-content: center;
  }
  .button-box-about {
    margin-top: 1rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1280px) {
  .about-shelter {
    position: unset;
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .csr-shelter {
    position: unset;
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .csr-shelter .px-5{
    padding: 0px !important;
  }
  .shelter-image {
    justify-content: center;
    margin-bottom: 1.2rem;
  }
  .shelter-image img {
    width: 100%;
  }
}
@media screen and (max-width: 974px) {
  .csr-shelter .px-5 ol li h3{
    font-size: 18px;
  }
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .about-me-title {
    font-size: 1.8rem;
  }
  .fluv-team-image {
    width: 150px;
    height: 150px;
  }
  .fluv-team-title {
    font-size: 2rem;
  }
  .about-me-fluid {
    padding: 0 20px !important;
  }
  .about-me-text {
    padding: 0.5rem !important;
  }
  .shelter-image {
    height: 400px;
    background-position-x: -180px;
  }
  .about-shelter {
    position: unset;
    width: 100%;
  }
  .csr-shelter {
    position: unset;
    width: 100%;
  }
  .about-shelter-title {
    font-size: 2.23rem;
  }
  .about-shelter p {
    color: white;
    font-weight: 900;
    font-size: 1.6rem;
  }
  .shelter-image-bg,
  .shelter-image {
    height: 300px;
  }
  .shelter-image-bg {
    background-position-x: -160px;
  }
}

@media screen and (min-width: 411px) and (max-width: 425px) {
  .shelter-image-bg {
    background-position: -180px;
  }
}
