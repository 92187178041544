
.blog-image {
  height: 208px;
  width: 313px;
  object-fit: cover;
}

@media(max-width: 991px) {
  .blog-image {
    width: 660px;
    height: 300px;
  }
}

@media(max-width: 420px) {
  .blog-image {
    height: 250px;
  }
}