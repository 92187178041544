.sitter-row .sitter-image {
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sitter-row .sitter-displayname h4 {
  font-size: 20px;
  font-weight: 900;
}

.sitter-row .sitter-experience p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.4px;
}

.sitter-row .sitter-learnmore .btn {
  font-size: 16px !important;
}

.sitter-row .sitter-rating {
  font-size: 20px;
}

.sitter-row .sitter-rating .rating-number {
  font-weight: 900;
  font-size: 16px;
  color: black;
}

.desktop-block {
  display: unset;
}

.mobile-block {
  display: none;
}


@media (max-width: 767px) {
  .mobile-block {
    display: unset;
  }
  .desktop-block {
    display: none;
  }
}