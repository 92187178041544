.search-service__button {
  font-size: 14px !important;
  padding: 5px;
  border-radius: 20px !important;
  line-height: 14px;
  box-shadow: rgba(74, 74, 74, 0.25) 0px 2px 3.84px;
  border: 0px;
}
.search-service__text--small {
  color: black;
  line-height: 1.5em;
  text-align: start;
  white-space: normal;
}

.search-service__button__text {
  font-size: 16px !important;
  padding: 8px;
  border-radius: 10px !important;
}

.active :last-child {
  color: #F5F5F5;
}
