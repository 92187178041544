.brand-logo {
  width: 200px;
  padding: 0px !important;
}

.brand-logo img {
  object-fit: cover;
}

@media (max-width: 480px) {
  .brand-logo img {
      height: 45px !important;
  }
}