.pet-adopt-banner {
  background-image: url("/assets/images/banner/home-banner/count-number-banner.png") !important;
  color: #f0f0f0 !important;
}
.pet-adopt-banner h4, .pet-adopt-banner h3 {
  color: #f0f0f0 !important;
}
.counter-number .web-counter-number{
  font-size: 50px;
  color: white;
}
.mobile-counter {
  display: none !important;
}
.service-layout .media-body p{
  overflow: unset;
  -webkit-line-clamp: unset;
  text-transform: unset;
  font-size: 20px;
  line-height: 36px;
  text-align: start;
}
.service-layout .media-body h4 {
  font-size: 30px !important;
}
.pet-parallax .odometer {
  font-size: 90px;
}
.pet-parallax .odometer .odometer-value{
  font-size: 90px;
}
.pet-parallax .full-banner{
  padding-top: 30px !important;
  padding-bottom: 80px !important;
}
/* 二度就業婦女—寵物保姆計畫 */
.blockquote {
  font-size: 30px;
}
.step .text-center{
  font-size: 30px;
}
.step svg {
  width: 45% !important;
  height: 45%;
}
/* .step:after, .step:before{
  content: '';
  display: inline-block;
  position: absolute;
  top: 45%;
  right: -20px;
  width: 0;
  height: 0;
  border-radius: 20px;
  border: 20px solid transparent;
  border-left-color: #f5275f;
}
.step:after{
  margin-right: 5px;
  border-left-color: white;
}
.step:last-child::after, .step:last-child::before {
  border-left-color: transparent;
  display: none;
} */
.arrow {
  border: solid #f5275f;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 10px;
  right: 0px;
  top: 50%;
  position: absolute;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.arrow-4 {
  display: none;
}
.service-block {
  display: unset !important;
}
.service-block .col-6 {
  padding: 20px;
}
.home-recommend .about-text p{
  font-size: 18px;
  letter-spacing: 7px;
  text-align: start !important;
}

.common-qa ol p{
  line-height: 24px;
}

.covid-warn {
  position: relative;
}

.covid-warn img {
  position: absolute;
  bottom: -60%;
  width: 200px;
  left: 1%;
}
.covid-warn .col-md-10{
  border-radius: 10px;
  padding: 30px;
  background-color: rgb(135 156 158 / 60%);
}
.covid-warn .row div {
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.close-warn {
  position: absolute;
  height: 30px;
  width: 30px;
  top: -6%;
  right: 9%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.close-warn .g-right {
  position: absolute;
  bottom: 5px;
  display: inline-block;
  width: 12px;
  height: 2px;
  background: #fff;
  line-height: 0;
  font-size: 0;
  vertical-align: middle;
  -webkit-transform: rotate(45deg);
}
.close-warn .g-right:after {
  content: "/";
  display: block;
  width: 12px;
  height: 2px;
  background: #fff;
  -webkit-transform: rotate(-90deg);
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .desktop-counter .web-counter-number{
    font-size: 38px;
  }
}

@media screen and (max-width: 767px){
  .desktop-counter {
    display: none;
  }
  .mobile-counter-title h2{
    color: white;
    line-height: 30px;
    text-align: start;
  }
  .mobile-counter {
    display: flex !important;
  }
  .mobile-counter .mobile-counter-number{
    font-size: 30px;
    color: white;
  }
  .arrow {
    border: solid #f5275f;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 6px;
    bottom: -15%;
    right: 47%;
    top: unset;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .arrow-4 {
    display: none;
  }
  .step {
    margin-top: 50px;
  }
  .step .text-center{
    font-size: 20px;
  }
  .step svg {
    width: 20% !important;
    height: 45%;
  }
  .common-qa ol {
    padding-left: 0px;
  }
  .covid-warn img {
    position: absolute;
    bottom: -33%;
    width: 150px;
    left: -3%;
  }
  .close-warn {
    top: -6%;
    right: 0%;
  }
  .pet-parallax .full-banner{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

#top #wrap_all .avia-button.av-kxoa0lfe-d805ce5398ce2d28b756262c26e538a9 {
  border-radius: 100px 100px 100px 100px;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

#top #wrap_all .avia-button.av-kxoa0lfe-d805ce5398ce2d28b756262c26e538a9:hover {
  color: white;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

#top #wrap_all.avia-button.av-kxoa0lfe-d805ce5398ce2d28b756262c26e538a9:hover .avia_button_background {
  border-radius: 100px 100px 100px 100px;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
}

#top .hr.hr-invisible.av-1qhz399-c03b4aade65c9f2714a6df0b3a0f006c {
  height: 10px;
}
.av_textblock_section .avia_textblock p{
  font-size:18px;
  line-height: 30px;
}

#top .hr.av-1s1gou5-7e55c087aeca14fe9e01d9f38c024dbf {
  margin-top: 30px;
}

.hr.av-1s1gou5-7e55c087aeca14fe9e01d9f38c024dbf .hr-inner {
  width: 50px;
  border-color: #000000;
}
.av-special-heading span  {
    font-size: 30px
}

@media screen and (max-width: 1200px) {
  .pet-parallax .odometer {
    font-size: 40px;
  }
  .pet-parallax .odometer .odometer-value{
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  .pet-parallax .odometer {
    font-size: 24px;
  }
  .pet-parallax .odometer .odometer-value{
    font-size: 24px;
  }
}

@media screen and (max-width: 425px) {
    .av-special-heading span {
      font-size: 24px;
    }
    .covid-warn .row div {
      font-size: 12px;
    }
    .covid-warn .col-md-10 {
      padding: 15px;
    }
    .covid-warn .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .close-warn .g-right {
      width: 10px;
      height: 2px;
    }
    .close-warn .g-right:after {
      width: 10px;
      height: 2px;
    }
    .close-warn {
      top: -6%;
      right: 3%;
      width: 25px;
      height: 25px;
    }
}

#top .av-special-heading.av-kxo9gqro-927b2fcd9f926ee82daa7f044fe87c03 {
  padding-bottom: 10px;
  font-size: 60px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    #dropin-cat-taipei-topbanner-button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .pet-sitter-img{
      margin: auto;
      margin-bottom: 30px;
      margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .desktop-counter .web-counter-number{
    font-size: 38px;
  }
}

@media screen and (max-width: 425px) {
  .title1.title5 .title-inner1{
   font-size: 28px !important;
  }
  .service-layout .media-body h4 {
    font-size: 24px !important;
  }
  .service-layout .media-body p {
    font-size: 18px !important;
    line-height: 30px;
  }
}
