.top-header {
  background-color: transparent !important;
  border: 0 !important;
}

.header-contact h6 {
  font-weight: 900;
  background: #f5275f;
  border-radius: 30px 0 0 30px;
  color: #ffffff;
  width: 100%;
  text-align: center;
  padding: 3px 18px;
  position: relative;
  border-right: 1px dashed #fff;
}

.header-contact h6:after {
  width: 0;
  height: 0;
  border: 12.5px solid transparent;
  position: absolute;
  content: "";
  border-top-color: #f5275f;
  right: -26px;
  top: 0px;
  border-left-color: #f5275f;
}

.header-contact h6:before {
  width: 0;
  height: 0;
  border: 13px solid transparent;
  position: absolute;
  content: "";
  border-bottom-color: #f5275f;
  right: -25px;
  top: -1px;
}

.header-contact ul li a{
  display: flex;
  align-items: center;
  color: rgb(251, 125, 140);
}
.header-contact ul li a div svg{
  fill: rgb(251, 125, 140);
}
.right-array {
  display: inline-block;
  width: 14px;
}
.top-download-app {
  padding: 15px 15px 15px 15px !important;
  background-color: rgb(251, 125, 140);
}
.top-download-app a {
  color: white !important;
  font-weight: 900;
}

.top-bar-white .container .mobile-socialmedia{
  padding: 0px;
}
@media (max-width: 1199px){

  .top-header .discount-block{
    padding-top: 10px;
  }
}

@media (max-width: 991px) {

}

@media screen and (max-width: 577px) {
  .top-header {
    height: 25px !important;
  }
}

@media screen and (max-width: 425px) {
  .discount-mobile-block h6{
    font-weight: 900;
    font-size: 14px;
  }
}

@media screen and (max-width: 397px) {
  .discount-mobile-block h6{
    font-size: 13px;
  }
  .topbar-mobile-discount {
    padding: 0;
    padding-left: 5px;
  }
}