.service-banner-1 {
  background-image: url("/assets/images/banner/service/cat-service.png") !important;
}
/* background-image: url("/assets/images/banner/home-banner/fluv-sitter-cat.png") !important; */
.next-block-first {
  border-left: unset !important;
}
.service-icon {
  width: 60px;
}
.service-block h4{
  font-size: 20px !important;
}

.service-slider-contain {
  backdrop-filter: blur(5px);
  padding: 25px;
  border-radius: 10px;
  background-color: rgba(255,255,255,0.3);
}

.service-block p {
  line-height: 20px;
  letter-spacing: 3px;
  margin-top: 10px;
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-line-clamp: 2;

  -webkit-box-orient: vertical;
}
.service-block h4 {
  color: black;
  margin-top: 10px;
}

.service-block + .service-block {
  border-left: 0 !important;
}

@media screen and (max-width: 767px) {
  .service-block h4{
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
  .service-block p {
    margin-top: 0px;
  }
}