.lookbook-section .row{
  margin: 0 auto;
}
.booking-process-svg svg {
  width: 15%;
}
.booking-process-svg svg path {
  fill: #5fcbc4;
}
.step-desktop h5 {
  font-size: 28px;
}
.step-mobile h5{
  font-size: 26px;
}

.step-desktop p, .step-mobile p {
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 24px;
}

.main-timeline {
  position: relative
}

.main-timeline:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: #c6c6c6;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0
}

.main-timeline .timeline {
  margin-bottom: 40px;
  position: relative
}

.main-timeline .timeline:after {
  content: "";
  display: block;
  clear: both
}

.main-timeline .icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.main-timeline .icon:before,
.main-timeline .icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.33s ease-out 0s
}

.main-timeline .icon:before {
  background: #fff;
  border: 2px solid #232323;
  left: -3px
}

.main-timeline .icon:after {
  border: 2px solid #c6c6c6;
  left: 3px
}

.main-timeline .timeline:hover .icon:before {
  left: 3px
}

.main-timeline .timeline:hover .icon:after {
  left: -3px
}

.main-timeline .date-content {
  width: 50%;
  float: left;
  margin-top: 22px;
  position: relative
}

.main-timeline .date-outer {
  width: 90%;
  font-size: 16px;
  text-align: center;
  margin: auto;
  z-index: 1
}

.main-timeline .date-outer:before {
  background: #fff;
  border: 2px solid #232323;
  left: -6px
}

.main-timeline .date-outer:after {
  border: 2px solid #c6c6c6;
  left: 6px
}

.main-timeline .timeline:hover .date-outer:before {
  left: 6px
}

.main-timeline .timeline:hover .date-outer:after {
  left: -6px
}

.main-timeline .date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 27%;
  left: 0
}

.main-timeline .month {
  font-size: 18px;
  font-weight: 700
}

.main-timeline .year {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #232323;
  line-height: 36px
}

.main-timeline .timeline-content {
  width: 50%;
  padding: 20px 0 20px 50px;
  float: right
}

.main-timeline .title {
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 15px 0
}

.main-timeline .description {
  margin-bottom: 0
}

.main-timeline .timeline:nth-child(2n) .date-content {
  float: right
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
  left: 10px
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
  padding: 20px 50px 20px 0;
  text-align: right
}

.step-mobile {
  display: none !important;
}

.step-desktop {
  display: flex;
}

.step-mobile span, .step-desktop span {
  font-size: 20px;
  font-weight: 900;
  color: black;
}

.download-button a{
  border-radius: 30px;
  text-decoration: none;
  padding: 13px 29px;
  color: white;
  background-color: #5fcbc4;
}

.download-button a:hover {
  border: 1px solid #5fcbc4;
  color: #5fcbc4;
  background-color: white;
}

@media only screen and (max-width: 991px) {
  .main-timeline .date-content {
    margin-top: 35px
  }

  .main-timeline .date-content:before {
    width: 22.5%
  }

  .main-timeline .timeline-content {
    padding: 10px 0 10px 30px
  }

  .main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 10px 30px 10px 0
  }
}

@media only screen and (max-width: 767px) {
  .step-desktop {
    display: none;
  }

  .step-mobile {
    display: unset !important;
  }

  .main-timeline:before {
    margin: 0;
    left: 7px
  }

  .main-timeline .timeline {
    margin-bottom: 20px
  }

  .main-timeline .timeline:last-child {
    margin-bottom: 0
  }

  .main-timeline .icon {
    margin: auto 0
  }

  .main-timeline .date-content {
    width: 95%;
    float: right;
    margin-top: 0
  }

  .main-timeline .date-content:before {
    display: none
  }

  .main-timeline .date-outer {
    width: 90%;
  }

  .main-timeline .date-outer:before,
  .main-timeline .date-outer:after {
    width: 110px;
    height: 110px
  }

  .main-timeline .date {
    top: 30%
  }

  .main-timeline .year {
    font-size: 24px
  }

  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    width: 95%;
    text-align: center;
    padding: 10px 0
  }

  .main-timeline .title {
    margin-bottom: 10px
  }
}

@media (max-width: 768px) {
  .booking-process-svg svg {
    width: 35%;
  }
}