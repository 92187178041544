.service-container {
  padding-left: 10%;
  padding-right: 10%;
}

.service-subtitle {
  line-height: 30px;
}

.service-detail-banner img{
  border-radius: 10px;
}

.service-subtitle {
  font-size: 18px;
}

.service-memo {
  font-size: 10px;
  color: #999999;
}

.testimonial {
  background-color: transparent !important;
}

@media screen and (max-width: 991px) {
  .service-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}