.sitter-one-page {
  margin: 0 auto;
  width: 40vw;
}

.sitter-one-page .banner {
  width: 100%;
  height: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sitter-one-page .top-area {
  width: 100%;
  height: 70px;
  position: relative;
}

.sitter-one-page .top-area .sitter-top-wrapper {
  position: absolute;
  width: 100%;
  height: 70px;
  top: -50px;
  padding: 0 3%;
}

.sitter-one-page .top-area .sitter-top-wrapper .avatar {
  cursor: pointer;
  width: 106px;
  height: 106px;
  border: solid white;
  border-radius: 50rem;
}

.sitter-one-page .top-area .sitter-top-wrapper .avatar .avatar-img {
  width: 100px;
  height: 100px;
  background-size: cover;
  display: block;
  border-radius: 50%;
}

.sitter-one-page .top-area .sitter-top-wrapper .rate .rate-text {
  font-weight: 700;
  color:#3d3d43;
}

.sitter-one-page .top-area .sitter-top-wrapper .rate .el-rate-icon {
  font-size: 14px;
  margin-right: 4px;
}

.sitter-one-page .top-area .sitter-top-wrapper .button-wrapper {
  margin-left: auto;

}

.sitter-one-page .top-area .sitter-top-wrapper .button-wrapper .button {
  padding: 5px 30px;
  font-size: 1.2rem;
  box-shadow: unset;
}

.sitter-one-page .title-display-name {
  width: 100%;
  cursor: pointer;
}

.sitter-one-page .title-display-name h1 {
  font-size: 1.5rem;
  margin: 0 3%;
}

.sitter-one-page .description p{
  line-height: 26px;
  font-size: 1rem;
  padding: 0 3%;
}

.sitter-one-page .service ul {
  list-style-type: none;
  padding: 3%;
}

.sitter-one-page .service ul .service-item {
  cursor: pointer;
  display: flex;
  align-items: self-start;
}

.sitter-one-page .service ul .service-item .image, .sitter-one-page .service ul .service-item .content-wrapper {
  width: 100%;
  display: inline-block;
}

.sitter-one-page .service ul .service-item .image {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
}

.sitter-one-page .service ul .service-item .content-wrapper .type h1, .sitter-one-page .service ul .service-item .content-wrapper .title h1{
  margin: 0;
}

.sitter-one-page .service ul .service-item .content-wrapper .type {
  font-size: 1.2rem;
  color: #59A7AE;
  font-weight: 700;
}

.sitter-one-page .service ul .service-item .content-wrapper .title {
  color: black;
  font-size: 1.3rem;
  font-weight: 600;
}

.sitter-one-page .service ul .service-item .content-wrapper .description {
  font-size: 1rem;
  color: black;
}

.service-detail-button .ga-web-download{
  border-radius: 50px;
}


@media screen and (max-width: 768px){
  .sitter-one-page {
    width: 100vw;
  }
}
