.service-box {
  cursor: pointer;
}

.slick-prev:before, .slick-next:before {
  color:rgb(251, 125, 140) !important;
}

.sitter-reviews h6{
  font-weight: 900;
}

.sitter-reviews .review-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0 gray;
  max-height: 150px;
}

.sitter-reviews .review-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;/*允許長單詞或 URL 地址進行換行。*/
  white-space: normal!important;/*空白被瀏覽器忽略*/
  line-height: 20px;
}

.service-img-box {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 100%;
  height: 290px;
  transform:scale(1,1);transition: all 1s ease-out;
  border-radius: 10px;
}

.service-img-box:hover {
  transform:scale(1.2,1.2);
}

.service-item {
  position: relative;
}

.profile-avatar-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profileImg {
  position: absolute;
  border-radius: 50%;
  bottom: 105px;
  right: 15px;
  z-index: 1;
  border: solid white;
}

.service-rating .fa-star, .service-rating-number, .service-rating-text, .service-reviews{
  color: rgb(251, 125, 140);
}

.fa-star-black {
  color: #212529 !important;
}

.service-item-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 320px;
  height: 320px;
}

.service-loading-image {
  width: 100%;
  height: 290px;
  background-repeat: no-repeat;
  background-position: center;
}

.service-item-banner img{
  width: 100%;
  height: 290px;
  object-fit: contain;
}

.service-detail h5, .modal-content .modal-body h5, .modal-content .modal-body .text-type{
  font-weight: 700;
  color: #79c7ce;
}

.styles_modal__gNwvD {
  max-width: 310px !important;
}

.service-bage span{
  background-color: rgb(251, 125, 140);
  color: white;
  font-size: 12px;
  padding: 2px;
  border-radius: 5px;
}

.text-weight {
  font-weight: 700;
}

.service-description-text, .sitter-description-text {
  white-space: pre-wrap;
}

.model-service-prices .service-prices, .model-service-prices .service-rating {
  font-size: 18px;
}

@media(max-width: 991px) {
  .service-image  {
    height: 252px;
  }
}

@media(max-width: 420px) {
  .service-image {
    height: 250px;
  }
}

@media(max-width: 375px) {
  .styles_modal__gNwvD {
    max-width: 330px !important;
  }
}

@media(max-width: 320px) {
  .styles_modal__gNwvD {
    max-width: 280px !important;
  }
}