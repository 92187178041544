.bubble-box {
  width: 100%;
  height: 250px;
  position: absolute;
  top: 55%;
  z-index: 1;
}

#bubble1, #bubble2, #bubble3, #bubble4, #bubble5, #bubble6, #bubble7 {
  position: absolute;
  border-radius: 50%;
  z-index: 0;
}

#bubble1 {
  width: 6vmin;
  height: 6vmin;
  background-color: #F5CBE2;
  left: -1%;
  bottom: 60px;
}

#bubble2 {
  width: 4vmin;
  height: 4vmin;
  background-color: #E76E7E;
  left: 18%;
  bottom: 0;
}

#bubble3 {
  width: 3.8vmin;
  height: 3.8vmin;
  background-color: #59A7AE;
  left: 15%;
  top: 10%;
}

#bubble4 {
  width: 5vmin;
  height: 5vmin;
  left: 49%;
  top: 23px;
  background-color: #F5CBE2;
}

#bubble5 {
  width: 3vmin;
  height: 3vmin;
  background-color: #F298A5;
  left: 86%;
}

#bubble6 {
  width: 4.2vmin;
  height: 4.2vmin;
  background-color: #FACC4F;
  left: 63%;
  bottom: -12%;
}

#bubble7 {
  width: 4.5vmin;
  height: 4.5vmin;
  background-color: #D2F0AC;
  left: 93.8%;
  top: -100px;
}