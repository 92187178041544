.sitter-item .quick-view-img {
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.styles_modal__gNwvD {
  padding-left: 0;
  padding-right: 0;
}
.sitter-item .quick-view-img img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: solid white 3px;
  position: absolute;
  top: 170px;
  left: 10px;
}
.service-rating span{
  color: darkgrey;
  font-weight: 900;
}

.sitter-reviews h6{
  font-weight: 900;
}

.sitter-reviews .review-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0 gray;
  max-height: 150px;
}

.sitter-reviews .review-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;/*允許長單詞或 URL 地址進行換行。*/
  white-space: normal!important;/*空白被瀏覽器忽略*/
  line-height: 20px;
}

.sitter-image {
  object-fit: cover;
  width: 100%;
  height: 252px;
  cursor: pointer;
}

.service-rating .fa-star, .service-rating-number, .service-rating-text{
  color: rgb(251, 125, 140);
}

.product-buttons .btn-solid {
  font-size: 16px !important;
}

@media(max-width: 991px) {
  .sitter-image  {
    height: 252px;
  }
}

@media(max-width: 420px) {
  .sitter-image {
    height: 250px;
  }
}