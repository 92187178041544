.cat {
  position: fixed;
  z-index: 100;
  width: 100%;
}
.cat_head {
  position: absolute;
  top: 200px;
  right: 0;
  transform: translate(100%, -50%);
  transition: transform 1.8s ease-in-out;
  cursor: pointer;
}

.cat_watch_anim {
  transform: translate(33%, -50%);
}

.cat_head svg {
  width: 200px;
  height: 200px;
}
.cat_paw {
  position: absolute;
  right: -10px;
  top: 350px;
  transform: translate(100%, -50%);
  transition: transform 1s ease-in-out;
  cursor: pointer;
}


.cat_paw_anim {
  transform: translate(-3%, -50%);
}

.cat_close_anim{
  transform: translate(100%, -50%) !important;
  transition: transform 1s ease-in-out;
}

.cat_paw_show_again_anim {
  transform: translate(-3%, -50%);
}
.cat_watch_show_again_anim {
  transform: translate(33%, -50%);
}

.cat_paw svg {
  width: 200px;
  height: 105px;
}

.cat_paw span{
  color: white;
  position: absolute;
  top: 53%;
  right: 10px;
  font-weight: 900;
}

.close_cat_paw {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
}
.g-right {
  position: absolute;
  bottom: 5px;
  display: inline-block;
  width: 12px;
  height: 2px;
  background: #999;
  line-height: 0;
  font-size: 0;
  vertical-align: middle;
  -webkit-transform: rotate(45deg);
}
.g-right:after {
  content: "/";
  display: block;
  width: 12px;
  height: 2px;
  background: #999;
  -webkit-transform: rotate(-90deg);
}

@media (max-width: 768px) {
  .cat_head svg {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 425px) {
  .cat_head {
    top: 100px;
  }
  .cat_head svg {
    width: 150px;
    height: 150px;
  }
  .cat_paw {
    top: 200px;
  }
  .cat_paw svg {
    width: 165px;
    height: 86px;
  }
  .cat_paw span{
    font-size: 10px;
  }
}