.sticky {
  background-color: transparent;
}

.search-item-box {
  height: auto;
  border: 1px solid #cfd7de;
  flex-grow: 1;
  position: relative;
  border: 1px solid #cfd7de;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  display: inline-block;
}

.download-app-icon {
  width: 25px;
  height: 25px;
}

.download-app-icon svg{
  fill: #999999;
}

.search-detail-memo {
  color: lightgray;
  font-size: 12px;
}
.search-detail-memo a {
  color: rgb(251, 125, 140);
}

.service-item-active {
  border-top: solid 3px #5fcbc4 !important;
}

.search-service-icon {
  width: 30px;
  display: block;
  margin: auto;
  margin-bottom: 5px;
}

.search-desktop {
  display: unset;
}
.search-mobile {
  display: none;
}

#search-overlay {
  overflow-y: auto;
}

.closebtn {
  z-index: 1;
}

.search-mobile {
  padding-top: 150px;
}

@media (min-width: 768px) {
  .search-desktop, .mobile-download, .mobile-search {
    display: none;
  }
}

@media (min-width: 578px) {
  .main-menu .menu-right .icon-nav .mobile-socialmedia i, .main-menu .menu-right .icon-nav .mobile-socialmedia-menu, .main-menu .menu-right .icon-nav .mobile-language, .mobile-search{
    display: none;
  }
}

@media (max-width: 577px) {
  .main-menu .menu-right .icon-nav .mobile-socialmedia {
    display: none;
  }
  /* mobile search */
  .main-menu .menu-right .icon-nav .mobile-search, .mobile-download {
    right: 60% !important;
  }
  /* mobile search */
  /* mobile socialmedia menu */
  .main-menu .menu-right .icon-nav .mobile-socialmedia-menu {
    position: fixed;
    bottom: 15px;
    right: 70%;
    font-size: 0;
    padding: 0;
    z-index: 9;
  }
  .main-menu .menu-right .icon-nav .mobile-socialmedia-menu i {
    font-size: 22px;
    font-family: FontAwesome;
    color: #999999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .main-menu .menu-right .icon-nav .mobile-socialmedia-menu .socialmedia {
    top: unset;
    bottom: 42px;
    left: unset !important;
  }
  .onhover-div .socialmedia, .onhover-div .language{
    min-width: 50px !important;
    border-radius: 10px !important;
  }
  /* mobile socialmedia menu */

  /* mobile language */
  .main-menu .menu-right .icon-nav .mobile-language {
    position: fixed;
    bottom: 15px;
    right: 3%;
    font-size: 0;
    padding: 0;
    z-index: 9;
  }
  .main-menu .menu-right .icon-nav .mobile-language .language {
    top: unset;
    bottom: 42px;
    left: unset !important;
  }
  .onhover-div .language{
    min-width: 50px;
    border-radius: 10px !important;
  }

  /* mobile language */



}